import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [accessToken, setAccessToken] = useState(null);  // Guardar el access token
  const [refreshToken, setRefreshToken] = useState(null);  // Guardar el refresh token
  const [user, setUser] = useState(null);  // Nuevo estado para la información del usuario
  const [loading, setLoading] = useState(true);  // Estado de carga inicial

  // Función para refrescar el access token
  const refreshAccessToken = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/refresh_token`, {
        method: 'POST',
        credentials: 'include',  // Enviar cookies para el refresh token
      });

      if (response.ok) {
        const data = await response.json();
        setAccessToken(data.jwt_token);  // Actualizar el nuevo token
        setIsLoggedIn(true);  // El usuario sigue autenticado
        return true;
      } else {
        setIsLoggedIn(false);
        return false;
      }
    } catch (error) {
      console.error('Error refreshing access token:', error);
      setIsLoggedIn(false);
      return false;
    }
  };

  // Verificar la autenticación al cargar la página
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/check-auth`, {
          method: 'GET',
          credentials: 'include',  // Asegura que las cookies HttpOnly se envíen con la solicitud
        });

        if (response.ok) {
          const data = await response.json();
          setAccessToken(data.access_token);  // Almacenar el access token recibido
          setRefreshToken(data.refresh_token);  // Almacenar el refresh token
          setUser(data.user);  // Almacenar la información del usuario
          setIsLoggedIn(true);          
        } else {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
        setIsLoggedIn(false);
      } finally {
        setLoading(false);  // Dejar de mostrar la pantalla de carga
      }
    };

    checkAuth();  // Verificar autenticación al cargar la página
  }, []);

  // Función de login
  const login = async (userCredentials) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userCredentials),
        credentials: 'include',  // Enviar cookies con los tokens
      });

      if (response.ok) {
        const data = await response.json();
        setAccessToken(data.jwt_token);  // Guardar el access token
        setRefreshToken(data.refresh_token);  // Guardar el refresh token
        setUser(data.user);  // Guardar la información del usuario después de iniciar sesión
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    } catch (error) {
      console.error('Error during login:', error);
      setIsLoggedIn(false);
    }
  };

  // Función de logout
  const logout = async () => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/logout`, {
        method: 'POST',
        credentials: 'include',
      });
      setAccessToken(null);  // Limpiar el token de acceso
      setRefreshToken(null);  // Limpiar el refresh token
      setUser(null);  // Limpiar la información del usuario al cerrar sesión
      setIsLoggedIn(false);
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout, refreshAccessToken, setLoading, loading, accessToken, user }}>
      {children}
    </AuthContext.Provider>
  );
};
