import React, { useState, useEffect, useContext } from 'react';
import './VisitedPosts.css';  // Usamos el mismo archivo de CSS para mantener consistencia
import { AuthContext } from './auth/AuthContext';
import { Link } from 'react-router-dom';  // Para los enlaces de los posts

const Recommendations = () => {
  const [recommendations, setRecommendations] = useState([]);
  const { refreshAccessToken, isLoggedIn, setLoading } = useContext(AuthContext);

  useEffect(() => {        
    const fetchRecommendations = async () => {

      setLoading(true);
      //setError(null);
      
      const response = await fetch(`${process.env.REACT_APP_API_URL}/recommendations`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',  // Agrega el encabezado Content-Type
        },
      });
      
      const data = await response.json();
      
      if (response.status === 401) {
        const tokenRefreshed = await refreshAccessToken();  // Intenta refrescar el token si es necesario
        if (tokenRefreshed) {
          await fetchRecommendations();  // Intenta de nuevo con el nuevo token
        } else {
          console.error('Unable to refresh token. Please log in again.');
        }
      } 

      setRecommendations(data.slice(0, 5));  // Limitar las recomendaciones a 5 posts
    };
            
    fetchRecommendations();
    setLoading(false);

  }, []);

  if (recommendations.length === 0) {
    return <div></div>;
  }

  return (
    <div className="visited-posts-container">
      <h3 className="visited-posts-header">Recommended Posts</h3>
      <ul className="visited-posts-list">
        {recommendations.map((post) => (
          <li key={post.messageid} className="visited-post-item">
            <Link to={`/post/${post.id}`} className="visited-post-link">
              <img
                src={`https://newspotweb.xyz/?page=getimage&messageid=${post.messageid}`}
                alt={post.title}
                className="visited-post-thumbnail"
              />
              <span className="visited-post-title">
                {post.title.length > 20 ? `${post.title.substring(0, 20)}...` : post.title}
              </span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Recommendations;
