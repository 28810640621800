import React, { useContext, useState, useEffect} from 'react';
import { AuthContext } from './auth/AuthContext';
import { Link } from 'react-router-dom';
import './VisitedPosts.css'; // Crear un archivo CSS separado para estilos

const VisitedPostsList = () => {
  const { isLoggedIn, refreshAccessToken} = useContext(AuthContext);
  const [visitedPosts, setVisitedPosts] = useState([]);
  //const visitedPosts = JSON.parse(localStorage.getItem('visitedPosts')) || [];

  useEffect(() => {    
    if (isLoggedIn) {
      // Si el usuario está logueado, consulta la base de datos
      const fetchVisitedPostsFromDB = async () => {
        try {
          const response = await fetch('/api/posts/visited', {
            method: 'GET',
            credentials: 'include',
          });
          const data = await response.json();
          /*if (response.status == 401){
             refreshAccessToken();            
          }*/
          
          setVisitedPosts(data.posts);  // Asumiendo que 'data.posts' contiene los últimos 10 posts
        } catch (error) {
          console.error("Error fetching visited posts from DB:", error);
        }
      };

      fetchVisitedPostsFromDB();

    } else {
      // Si no está logueado, usa los datos del LocalStorage
      const localVisitedPosts = JSON.parse(localStorage.getItem('visitedPosts')) || [];
      setVisitedPosts(localVisitedPosts);
    }
  }, [isLoggedIn]);

  return (
    <div className="visited-posts-container">
      <h3 className="visited-posts-header">Last Viewed Posts</h3>
      <ul className="visited-posts-list">
        {visitedPosts.map((post) => (
          <li key={post.id} className="visited-post-item">
            <Link to={`/post/${post.id}`} className="visited-post-link">
            {!isLoggedIn && (
              <img 
                src={post.imageurl} 
                alt={post.title} 
                className="visited-post-thumbnail"
              />
              )}
              {isLoggedIn && (
              <img 
                src={post.messageid} 
                alt={post.title} 
                className="visited-post-thumbnail"
              />
              )}
              <span className="visited-post-title">
                {post.title.length > 20 ? `${post.title.substring(0, 20)}...` : post.title}
              </span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default VisitedPostsList;

