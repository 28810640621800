import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from './auth/AuthContext';
import { Link, useNavigate, useParams } from 'react-router-dom';
import './PostDetail.css';
import './SpotwebTable.css';
import './Spinner.css';
import CommentsList from './CommentsList'; // Import the CommentsList component
import { FaArrowLeft, FaDownload } from 'react-icons/fa';

const PostDetail = () => {
  const { isLoggedIn} = useContext(AuthContext);
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [newComment, setNewComment] = useState('');
  const [comments, setComments] = useState([]);
  const navigate = useNavigate(); // Hook for navigation

function savePostVisited(post) {
  const visitedPosts = JSON.parse(localStorage.getItem('visitedPosts')) || [];
  // If it already exists, we delete it to reinsert it at the beginning.
  const existingIndex = visitedPosts.findIndex(item => item.id === post.id);
  if (existingIndex > -1) {
    visitedPosts.splice(existingIndex, 1);
  }
  // Add the post to the top
  visitedPosts.unshift(post);
  // Limit to 10 posts
  if (visitedPosts.length > 10) {
    visitedPosts.pop();
  }
  localStorage.setItem('visitedPosts', JSON.stringify(visitedPosts));
}

function deletePostVisited(id) {
  const visitedPosts = JSON.parse(localStorage.getItem('visitedPosts')) || [];

  // If the post does not exist, remove it
  const existingIndex = visitedPosts.findIndex(item => item.id === id);
  if (existingIndex !== -1) {
    const updatedPosts = visitedPosts.filter(item => item.id !== id);
    localStorage.setItem('visitedPosts', JSON.stringify(updatedPosts));
    console.log(`Post with id ${id} removed from localStorage.`);
  }
}

async function fetchInitialData(id) {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/post/${id}`, {
    method: 'GET',
    credentials: 'include',
  });
  const data = await response.json();
  if (!data[0]) {
    // Si el post no existe, redirige a la página de inicio y lo elimina del localStorage
    deletePostVisited(Number(id));
    navigate('/'); // Redirige a la página principal
  } else {
    setPost(data[0]);
    savePostVisited(data[0]);

    // Registrar la visita en el backend
    if(isLoggedIn){
       registerVisit(id, data[0]);
    }

    // Si la descripción es nula o está vacía, iniciar reintentos
    if (!data[0].description || data[0].description.trim() === "") {
      retryFetchDescription(id);
    }
  }
}

  // Retry fetching description if it's missing
  async function retryFetchDescription(id, retries = 5, delay = 2000) {
    for (let attempt = 1; attempt <= retries; attempt++) {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/post/${id}`, {
        method: 'GET',
        credentials: 'include',
    });
      const data = await response.json();

      if (data[0].description && data[0].description.trim() !== "") {
        setPost(prevPost => ({ ...prevPost, description: data[0].description }));
        break; // Stop retrying if a valid description is found
      } else {
        await new Promise(resolve => setTimeout(resolve, delay)); // Wait before retrying
      }
    }
  }

 const handleAddCommentClick = () => {
    if (!isLoggedIn) return;
    setShowCommentBox(!showCommentBox);
    setNewComment('');
  };

 const handlePostComment = async (id,postId) => {
    if (!isLoggedIn) return;
    console.log("Post comment click button...")
    if (!newComment.trim()) return;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/comment/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({      
          messageid: postId,
          comment: newComment,
        }),
      });

      if (response.ok) {
        const newComment = await response.json();
        setComments([...comments, newComment]); // Agrega el nuevo comentario a la lista
	//setComment(''); // Limpia el input después de enviar
        setShowCommentBox(false);
        setNewComment('');
      }
    } catch (error) {
      console.error('Error al enviar el comentario:', error);
    }
  };

  const registerVisit = async (id, post) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/register_visit`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({          
          post_id: id,
          messageid: post.messageid,
          category: post.category,
          subcata: post.categories_info,  // O 'subcata' si lo necesitas para otra estructura
        }),
      });

      if (response.ok) {
        console.log('Visit registered successfully');
      } else {
        console.error('Failed to register visit');
      }
    } catch (error) {
      console.error('Error registering visit:', error);
    }
  };

  useEffect(() => {
    fetchInitialData(id);
  }, [id]);

  const handleBackClick = () => {
    navigate(-1);
  };

  if (!post) {
    return <div className="loading-animation">
      <div className="spinner"></div>
      <p>Loading ...</p>
    </div>;
  }

  const formattedDescription = (post.description || "")
    .replace(/\[b\]/g, '<b>')
    .replace(/\[\/b\]/g, '</b>')
    .replace(/\[i\]/g, '<i>')
    .replace(/\[\/i\]/g, '</i>')
    .replace(/\[br\]/g, '<br />')
    .replace(/\[u\]/g, '<u>')
    .replace(/\[\/u\]/g, '</u>')
    .replace(/\n/g, '<br />');

  const getCategoryClass = (category) => {
    switch (category) {
      case 0:
        return 'cat0';
      case 1:
        return 'cat1';
      case 2:
        return 'cat2';
      case 3:
        return 'cat3';
      default:
        return '';
    }
  };

  function formatSubcategories(subcategories) {
    return subcategories.split('|').filter(Boolean);
  }

 function formattedComment(comment){
  (comment || "")
    .replace(/\[b\]/g, '<b>')
    .replace(/\[\/b\]/g, '</b>')
    .replace(/\[i\]/g, '<i>')
    .replace(/\[\/i\]/g, '</i>')
    .replace(/\[br\]/g, '<br />')
    .replace(/\[u\]/g, '<u>')
    .replace(/\[\/u\]/g, '</u>')
    .replace(/\n/g, '<br />');
    return comment;
 }

 const handleDownloadNZB = async (messageid, post_id) => {
  try {
    // Registrar la descarga en el backend
    const response = await fetch(`${process.env.REACT_APP_API_URL}/register_download`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({        
        post_id: post_id,
        messageid: messageid,
      }),
    });

    if (response.ok) {
      console.log('Download registered successfully');
    } else {
      console.error('Failed to register download');
    }
  } catch (error) {
    console.error('Error registering download:', error);
  }

  // Continuar con el proceso de descarga del archivo NZB
  window.location.href = messageid; // Iniciar la descarga
};


  const subcategories = formatSubcategories(post.subcategories);

  return (
    <div className="post-detail-container flex justify-start">
      <button onClick={handleBackClick}
        className="mt-4 bg-green-500 text-white py-2 px-4 rounded flex items-center">
        <FaArrowLeft className="mr-2" /> {/* Icon with a small margin to right */}
        Back to Posts
      </button>
      <div className="post-info">
        <h2>{post.title}</h2>
      </div>
      <div className="post-detail-header flex" style={{ alignItems: 'flex-start' }}>
        <img src={post.imageurl} alt={post.title} className="post-image" style={{ maxWidth: '40%', marginRight: '20px' }} />
        <table className="details-table" style={{ flex: 1 }}>
          <tbody>
            <tr>
              <td style={{ textAlign: 'center', fontWeight: 'bold' }}>Category:</td>
              <td style={{ textAlign: 'left' }}>
                <button className={`category-button ${getCategoryClass(post.category)}`} style={{ width: '50%' }}>
                  {post.subcata}
                </button>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: 'center', fontWeight: 'bold' }}>Poster:</td>
              <td style={{ textAlign: 'left' }}>{post.poster}</td>
            </tr>
            <tr>
              <td style={{ textAlign: 'center', fontWeight: 'bold' }}>Size:</td>
              <td style={{ textAlign: 'left' }}>{post.filesize}</td>
            </tr>
            <tr>
              <td style={{ textAlign: 'center', fontWeight: 'bold' }}>Created:</td>
              <td style={{ textAlign: 'left' }}>{post.stamp}</td>
            </tr>
            {subcategories.map((subcat, index) => (
              <tr key={index}>
                <td className="label" style={{ textAlign: 'center', fontWeight: 'bold' }}>
                  {subcat.split(':')[0]}:
                </td>
                <td style={{ textAlign: 'left' }}>{subcat.split(':')[1]}</td>
              </tr>
            ))}
            <tr>
              <td style={{ textAlign: 'center', fontWeight: 'bold' }}>NZB:</td>
              <td style={{ textAlign: 'left' }}>
                <a onClick={() => handleDownloadNZB(post.messageid, post.id)}  // Llamar la función de descarga
                  className="nzb-link"
                  download
                  style={{ textAlign: 'left', cursor: 'pointer' }}
                >
                  NZB <FaDownload />
                </a>
              </td>
            </tr>
            {post.spotnetv && (
              <tr>
                <td style={{ textAlign: 'center', fontWeight: 'bold' }}>Spotted with:</td>
                <td style={{ textAlign: 'left' }}>{post.spotnetv}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Post Description Section */}
      <div className="post-description">
        <h2 className="section-header">Post Description</h2>
        <table className="description-table">
          <tbody>
            <tr>
              {formattedDescription && (
                <td dangerouslySetInnerHTML={{ __html: formattedDescription }} />
              )}
            </tr>
          </tbody>
        </table>
      </div>
     {isLoggedIn && (
      <div className="add-comment">
      <button onClick={() => setShowCommentBox(!showCommentBox)}>
        {showCommentBox ? 'Cancel' : 'Add Comment'}
      </button>

      {showCommentBox && (
        <div className="comment-box">
          <form onSubmit={handlePostComment}> {/* Form para manejar el comentario */}
            <textarea
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              placeholder="Write your comment..."
            />
            <button type="submit" disabled={newComment.trim() === ''}>
              Post
            </button>
          </form>
        </div>
      )}
     </div>
     )}

      {/* Comments Section */}
      <div className="post-comments">
        <h2 className="section-header">Comments</h2>
        {post.comments && post.comments.length > 0 ? (
          <CommentsList comments={post.comments} />
        ) : (
          <p>No comments yet. Be the first to comment!</p>
        )}
      </div>
    </div>
  );
};

export default PostDetail;
